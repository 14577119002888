export default {
  adminUiEndpoint: 'https://app.dev.playable.com',
  apiEndpoint: 'https://campaign-api-rest.dev.playable.com',
  leaderboardUI: 'https://cdn.dev.leadfamly.com/frontend/leaderboard/leaderboard.js',
  facebookId: '0',
  sentry: {
    dsn: 'https://46f7effaa6c94756ba9e977d80c75462@o367362.ingest.sentry.io/4504678627409920', // nosem
    environment: 'dev'
  },
  adminUiEndpointOverwrites: [
    {
      lookup: 'app.dev.playable.com',
      overwrite: 'https://app.dev.playable.com'
    }
  ],
  analyticsEndpoint: 'https://collector.dev.data.playable.com/'
};
