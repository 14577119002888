import type { AddonLeaderboardv2Data, Color, TimeFormat } from './Data';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import AddonModel from '@/src/models/grid/AddonModel';
import { AlignContentType, TextDecorationType, TextStyleType, TextTransformType } from '@/src/typings/enums/enums';
import { getDeviceData } from '@/src/hooks/useDevice';

export interface AddonLeaderboardv2LayoutHeadingState {
  backgroundColor?: Color;
  color?: Color;
  fontFamily?: string;
  fontWeight?: number;
  fontSize?: number;
  fontStyle?: TextStyleType;
  letterSpacing?: number;
  lineHeight?: number;
  textDecoration?: TextDecorationType;
  textTransform?: TextTransformType;
}

export interface AddonLeaderboardv2LayoutBodyState {
  backgroundColor?: Color;
  color?: Color;
  fontFamily?: string;
  fontWeight?: number;
  fontSize?: number;
  fontStyle?: TextStyleType;

  highlight: {
    enabled: boolean;
    backgroundColor?: Color;
    color?: Color;
  };

  letterSpacing?: number;
  lineHeight?: number;
  textDecoration?: TextDecorationType;
  textTransform?: TextTransformType;

  secondary?: {
    enabled: boolean;
    backgroundColor?: Color;
    color?: Color;
  };
}

export enum TimeScoreFormat {
  DEFAULT,
  SECONDS_TWO_DECIMALS,
  SECONDS_THREE_DECIMALS
}

export interface AddonLeaderboardv2State extends AddonModelState {
  leaderboardId?: number;
  limit: number;

  rankingDefaultLabel: string;

  effects: {
    countUpScore: boolean;
    enterAnimation: boolean;
    highlightTop3: boolean;
  };

  autoUpdate: {
    enabled: boolean;
    time?: number;
  };

  icons: {
    firstPlace: {
      enabled: boolean;
      image?: string;
      width?: number;
    };

    secondPlace: {
      enabled: boolean;
      image?: string;
      width?: number;
    };

    thirdPlace: {
      enabled: boolean;
      image?: string;
      width?: number;
    };

    userPlace: {
      enabled: boolean;
      image?: string;
      width?: number;
    };
  };

  headings: {
    name: {
      label: string;
      alignment: AlignContentType;
    };

    rank: {
      label: string;
      alignment: AlignContentType;
    };

    score: {
      label: string;
      alignment: AlignContentType;
      timeFormat?: TimeScoreFormat;
    };
  };

  hideNameColumn: boolean;

  layout: {
    heading: AddonLeaderboardv2LayoutHeadingState;
    body: AddonLeaderboardv2LayoutBodyState;
  };
}

export class AddonLeaderboardv2Model extends AddonModel<
  AddonLeaderboardv2Data,
  AddonLeaderboardv2State,
  'leaderboard-v2'
> {
  parseAddon(data: AddonLeaderboardv2Data): void {
    this.state.leaderboardId = data.settings.leaderboard ? Number(data.settings.leaderboard) : undefined;
    this.state.rankingDefaultLabel = data.settings.ranking_default_label ?? 'Points';

    this.state.limit = data.settings.limit ? Number(data.settings.limit) : 10;

    this.state.effects = {
      countUpScore: data.settings.effects?.count_up_score === '1',
      enterAnimation: data.settings.effects?.enter_animation === '1',
      highlightTop3: data.settings.effects?.highlight_top3 === '1'
    };

    this.state.autoUpdate = {
      enabled: data.settings.autoupdate?.enabled === '1',
      time: data.settings.autoupdate?.time ? Number(data.settings.autoupdate.time) : undefined
    };

    this.state.hideNameColumn = data.settings?.has_name_column === false;

    this.state.headings = {
      name: {
        label: data.settings.headings?.name?.label ? data.settings.headings?.name?.label : 'Name',
        alignment: AddonLeaderboardv2Model.parseAlignment(data.settings.headings?.name?.alignment)
      },

      rank: {
        label: data.settings.headings?.rank?.label ? data.settings.headings?.rank?.label : '#',
        alignment: AddonLeaderboardv2Model.parseAlignment(
          data.settings.headings?.rank?.alignment,
          AlignContentType.CENTER
        )
      },

      score: {
        label: data.settings.headings?.score?.label
          ? data.settings.headings?.score?.label
          : this.state.rankingDefaultLabel,
        alignment: AddonLeaderboardv2Model.parseAlignment(
          data.settings.headings?.score?.alignment,
          AlignContentType.CENTER
        ),
        ...(data.settings.headings?.score?.time_format && {
          timeFormat: AddonLeaderboardv2Model.parseTimeFormat(data.settings.headings.score.time_format)
        })
      }
    };

    const layoutHeadingData = data.settings.layout?.heading ? getDeviceData(data.settings.layout?.heading) : null;
    const layoutBodyData = data.settings.layout?.body ? getDeviceData(data.settings.layout?.body) : null;

    this.state.layout = {
      heading: {
        backgroundColor: layoutHeadingData?.background_color,
        color: layoutHeadingData?.color,
        fontFamily: layoutHeadingData?.font_family,
        fontWeight: layoutHeadingData?.font_weight ? Number(layoutHeadingData.font_weight) : undefined,
        fontSize: layoutHeadingData?.font_size ? parseInt(layoutHeadingData.font_size) : undefined,
        letterSpacing: layoutHeadingData?.letter_spacing ? Number(layoutHeadingData.letter_spacing) : undefined,
        lineHeight: layoutHeadingData?.line_height ? Number(layoutHeadingData.line_height) : undefined,
        textDecoration: AddonLeaderboardv2Model.parseTextDecoration(layoutHeadingData?.text_decoration),
        fontStyle: AddonLeaderboardv2Model.parseFontStyle(layoutHeadingData?.font_style),
        textTransform: AddonLeaderboardv2Model.parseTextTransform(layoutHeadingData?.text_transform)
      },
      body: {
        backgroundColor: layoutBodyData?.background_color,
        color: layoutBodyData?.color,
        fontFamily: layoutBodyData?.font_family,
        fontWeight: layoutBodyData?.font_weight ? Number(layoutBodyData.font_weight) : undefined,
        fontSize: layoutBodyData?.font_size ? parseInt(layoutBodyData.font_size) : undefined,
        letterSpacing: layoutBodyData?.letter_spacing ? Number(layoutBodyData.letter_spacing) : undefined,
        lineHeight: layoutBodyData?.line_height ? Number(layoutBodyData.line_height) : undefined,
        textDecoration: AddonLeaderboardv2Model.parseTextDecoration(layoutBodyData?.text_decoration),
        fontStyle: AddonLeaderboardv2Model.parseFontStyle(layoutBodyData?.font_style),
        textTransform: AddonLeaderboardv2Model.parseTextTransform(layoutBodyData?.text_transform),

        highlight: {
          enabled: layoutBodyData?.highlight === '1',
          backgroundColor: layoutBodyData?.highlight_background_color,
          color: layoutBodyData?.highlight_color
        },

        secondary: {
          enabled: layoutBodyData?.secondary === '1',
          backgroundColor: layoutBodyData?.secondary_background_color ?? 'rgba(255, 255, 255, 0.05)',
          color: layoutBodyData?.secondary_color
        }
      }
    };

    this.state.icons = {
      firstPlace: {
        enabled: data.settings.icons?.first_place?.enabled === '1' && !!data.settings.icons?.first_place?.image,
        image: data.settings.icons?.first_place?.image,
        width: data.settings.icons?.first_place?.width ? parseInt(data.settings.icons?.first_place?.width) : undefined
      },

      secondPlace: {
        enabled: data.settings.icons?.second_place?.enabled === '1' && !!data.settings.icons?.second_place?.image,
        image: data.settings.icons?.second_place?.image,
        width: data.settings.icons?.second_place?.width ? parseInt(data.settings.icons?.second_place?.width) : undefined
      },

      thirdPlace: {
        enabled: data.settings.icons?.third_place?.enabled === '1' && !!data.settings.icons?.third_place?.image,
        image: data.settings.icons?.third_place?.image,
        width: data.settings.icons?.third_place?.width ? parseInt(data.settings.icons?.third_place?.width) : undefined
      },

      userPlace: {
        enabled: data.settings.icons?.user_place?.enabled === '1' && !!data.settings.icons?.user_place?.image,
        image: data.settings.icons?.user_place?.image,
        width: data.settings.icons?.user_place?.width ? parseInt(data.settings.icons?.user_place?.width) : undefined
      }
    };
  }

  // Function to parse timeFormat from string to TimeScoreFormat enum
  private static parseTimeFormat(format: TimeFormat): TimeScoreFormat | undefined {
    switch (format) {
      case 'seconds_two_decimals':
        return TimeScoreFormat.SECONDS_TWO_DECIMALS;
      case 'seconds_three_decimals':
        return TimeScoreFormat.SECONDS_THREE_DECIMALS;
      case 'default':
      default:
        return TimeScoreFormat.DEFAULT;
    }
  }

  public isAddonValid(): boolean {
    const data = this.getData();
    return !!data.settings.leaderboard;
  }

  private static parseAlignment(alignment?: string, defaultAlignment = AlignContentType.LEFT): AlignContentType {
    switch (alignment) {
      case 'left':
        return AlignContentType.LEFT;

      case 'right':
        return AlignContentType.RIGHT;

      case 'center':
        return AlignContentType.CENTER;

      default:
        return defaultAlignment;
    }
  }

  private static parseTextDecoration(value?: string): TextDecorationType {
    switch (value) {
      case 'none':
        return TextDecorationType.NONE;

      case 'line-through':
        return TextDecorationType.LINE_THROUGH;

      case 'overline':
        return TextDecorationType.OVERLINE;

      case 'underline':
        return TextDecorationType.UNDERLINE;

      default:
        return TextDecorationType.DEFAULT;
    }
  }

  private static parseFontStyle(value?: string): TextStyleType {
    switch (value) {
      case 'normal':
        return TextStyleType.NORMAL;

      case 'italic':
        return TextStyleType.ITALIC;

      case 'oblique':
        return TextStyleType.OBLIQUE;

      default:
        return TextStyleType.DEFAULT;
    }
  }

  private static parseTextTransform(value?: string): TextTransformType {
    switch (value) {
      case 'none':
        return TextTransformType.NONE;

      case 'lowercase':
        return TextTransformType.LOWERCASE;

      case 'uppercase':
        return TextTransformType.UPPERCASE;

      case 'capitalize':
        return TextTransformType.CAPITALIZE;

      default:
        return TextTransformType.DEFAULT;
    }
  }
}
