import type { Component } from 'vue';

import Snow1 from '../../../assets/images/particles/snow/snowflake.png';
import Snow2 from '../../../assets/images/particles/snow/snowflake-2.png';
import Flag from '../../../assets/images/particles/flags/flag.png';

import type { IntegrationParticleGeneratorData } from './Data';
import type { BaseIntegrationState } from '@/src/models/integrations/BaseIntegration';
import { BaseIntegration } from '@/src/models/integrations/BaseIntegration';

export enum ParticleGeneratorVariant {
  SNOW,
  CONFETTI,
  FLAGS,
  CUSTOM
}

export enum ParticleGeneratorLocation {
  BODY,
  SECTION,
  FLOWPAGE
}

export interface ParticleGeneratorFlowPage {
  enabled: boolean;
  ids?: number[];
}

export interface ParticleGeneratorSection {
  enabled: boolean;
  id?: number;
  selectorClass?: string;
}

interface ParticleGeneratorIntensityLevels {
  very_low: ParticleGeneratorIntensityValue;
  low: ParticleGeneratorIntensityValue;
  medium: ParticleGeneratorIntensityValue;
  high: ParticleGeneratorIntensityValue;
  very_high: ParticleGeneratorIntensityValue;
}

interface ParticleGeneratorIntensityValue {
  particles: number;
  speed: number;
}

export interface IntegrationParticleGeneratorState extends BaseIntegrationState {
  variant?: ParticleGeneratorVariant;
  flowpage: ParticleGeneratorFlowPage;
  section: ParticleGeneratorSection;
  body: {
    enabled: boolean;
    onSpecificFlowPages: boolean;
    flowPages?: number[];
  };
  particle: {
    type: ParticleGeneratorVariant;
    images?: string[];
  };
  intensity: {
    particles: number;
    speed: number;
  };
  duration: 'always' | number;
  placement: 'front' | 'behind';
  flakeImages?: string[];
}

export class IntegrationParticleGeneratorModel extends BaseIntegration<
  IntegrationParticleGeneratorData,
  IntegrationParticleGeneratorState
> {
  parse(data: IntegrationParticleGeneratorData) {
    super.parse(data);

    this.state.placement = data.settings?.particle_generator.placement ?? 'behind';

    this.state.duration =
      data.settings?.particle_generator.duration === 'always'
        ? 'always'
        : Number(data.settings?.particle_generator.duration) ?? 'always';

    const defaultDifficulties: ParticleGeneratorIntensityLevels = {
      very_low: { particles: 40, speed: 5 },
      low: { particles: 100, speed: 5 },
      medium: { particles: 1000, speed: 5 },
      high: { particles: 2000, speed: 10 },
      very_high: { particles: 4000, speed: 15 }
    };

    const level = data.settings?.particle_generator.intensity?.level ?? 'very_low';
    const isCustom = data.settings?.particle_generator.intensity?.custom_enabled === '1';
    let intensity: ParticleGeneratorIntensityValue;

    if (isCustom) {
      intensity = {
        particles: Number(data.settings?.particle_generator.intensity?.custom_particles) ?? 1000,
        speed: Number(data.settings?.particle_generator.intensity?.custom_speed) ?? 5
      };
    } else {
      switch (level) {
        case 'very_low':
          intensity = defaultDifficulties.low;
          break;

        case 'low':
          intensity = defaultDifficulties.low;
          break;

        case 'medium':
          intensity = defaultDifficulties.medium;
          break;

        case 'high':
          intensity = defaultDifficulties.high;
          break;

        case 'very_high':
          intensity = defaultDifficulties.very_high;
          break;
      }
    }

    this.state.intensity = { ...intensity };

    this.state.body = {
      enabled: false,
      onSpecificFlowPages: false
    };

    this.state.section = {
      enabled: false
    };

    this.state.flowpage = {
      enabled: false
    };

    let variant: ParticleGeneratorVariant = ParticleGeneratorVariant.SNOW;

    if (data.settings?.particle_generator.variant) {
      switch (data.settings.particle_generator.variant) {
        case 'snow':
          variant = ParticleGeneratorVariant.SNOW;
          break;

        case 'confetti':
          variant = ParticleGeneratorVariant.CONFETTI;
          break;

        case 'flags':
          variant = ParticleGeneratorVariant.FLAGS;
          break;

        case 'custom':
          variant = ParticleGeneratorVariant.CUSTOM;
          break;
      }

      this.state.variant = variant;
    }

    if (data.settings?.particle_generator.render) {
      let flowPages;

      switch (data.settings.particle_generator.render) {
        case 'section':
          this.state.section = this.constructSectionModel(data.settings.particle_generator.section);
          break;

        case 'flowpage':
          this.state.flowpage = this.constructFlowPageModel(data.settings.particle_generator.flowpage);
          break;

        default:
          this.state.body = {
            enabled: true,
            onSpecificFlowPages: false
          };

          if (
            data.settings.particle_generator.flowpage &&
            typeof data.settings.particle_generator.flowpage !== 'string'
          ) {
            flowPages = data.settings.particle_generator.flowpage.map((id) => {
              return Number(id);
            });

            this.state.body.onSpecificFlowPages = flowPages?.length > 0;
            this.state.body.flowPages = flowPages;
          }
      }
    }

    this.state.particle = {
      type: variant,
      images: []
    };

    if (this.state.particle && this.state.particle.images) {
      switch (this.state.particle.type) {
        case ParticleGeneratorVariant.SNOW:
          this.state.particle.images.push(Snow1, Snow2);
          break;

        case ParticleGeneratorVariant.CONFETTI:
          break;

        case ParticleGeneratorVariant.FLAGS:
          this.state.particle.images.push(Flag);
          break;

        case ParticleGeneratorVariant.CUSTOM:
          if (data.settings?.particle_generator.flake_image) {
            this.state.particle.images.push(data.settings.particle_generator.flake_image);
          }

          if (data.settings?.particle_generator.flake_image_2) {
            this.state.particle.images.push(data.settings.particle_generator.flake_image_2);
          }

          if (data.settings?.particle_generator.flake_image_3) {
            this.state.particle.images.push(data.settings.particle_generator.flake_image_3);
          }
          break;
      }
    }
  }

  constructFlowPageModel(data: string[] | string | undefined): ParticleGeneratorFlowPage {
    const flowpage: ParticleGeneratorFlowPage = {
      enabled: false
    };

    if (data === undefined) {
      return flowpage;
    }

    flowpage.enabled = true;

    if (typeof data === 'string') {
      const flowpageId = Number(data);
      flowpage.ids = [flowpageId];
    } else {
      flowpage.ids = data.map((flowPageId) => {
        return Number(flowPageId);
      });
    }

    return flowpage;
  }

  constructSectionModel(data: string | undefined) {
    const section: ParticleGeneratorSection = {
      enabled: false
    };

    if (data === undefined) {
      return section;
    }

    if (typeof data === 'string') {
      section.enabled = true;
      section.selectorClass = `.section--${data}`;
      section.id = Number(data);
    }

    return section;
  }

  getVueComponent(): Promise<Component> {
    return import('@/src/components/integrations/particle-generator/View.vue');
  }

  hasVueComponent() {
    return true;
  }
}
